@import "../global-styles";

.button,
.button-blue,
.button-light,
.button-clear,
.button-red
.button-gray {
    padding: 0.725rem 1.875rem;
    border-radius: 5px;
    min-width: 12rem;
    cursor: pointer;
    border: none;
    text-decoration: none;
    font-size: 1.1rem;
    @include transition(500ms);
    &:hover {
        @include transition(500ms);
    }
}
.button-red{
    background-color : #F44336;
    color: $white;
    border: 2px solid #F44336;
    &:hover {
        background-color: #e57373;
    }
}
.button-blue{
    background-color: $indigo;
    color: $white;
    border: 2px solid $indigo;
    &:hover {
        background-color: $indigo_hover;
    }
}
.button-light{
    border: 2px solid $gray;
    background-color: unset;
    color: $gray;
    &:hover {
        background-color: $gray;
        color: $white;
    }
}
.button-gray{
    border: 2px solid $gray;
    background-color: $gray;
    color: $white;
    // &:hover {
    //     background-color: $gray;
    //     color: $white;
    // }
}
.button-clear{
    background-color: none;
    color: $white;
    border: 2px solid $white;
}

.inactive{
    &:hover {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
    }
}