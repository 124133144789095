@import "../global-styles";

$transition-duration: 0.3s;

.records-container {
    //padding: 2em;
    transition: margin-left $transition-duration ease-in-out;
    position: relative;
    width: calc(100% - 2.5rem);
	padding-right: 10px;
    top: 2.5em;
    @include mq(1100px){
        top: -1.5em;
    }
    &.shifted {
        margin-left: 375px; // This should match the width of the drawer
		padding-right: 15px;
    }

    .header {
        
        width: 100%;
        
        padding-bottom: 1rem;
        border-bottom: .15em solid $silver;
        @include mq(1100px){
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
        }
        .logo-and-title {
            
            margin-bottom: 1em;
            @include mq(715px){
                display: inline-flex;
            align-items: center;
            }
            @include mq(1100px){
                margin-bottom: unset;
            }
            .logo-wrapper{
                height: 4rem;
                width: auto;
                padding: 0 0 1em;
                @include mq(715px){
                    border-right: .15em solid $silver;
                    text-align: center;
                    padding:0 2em 0 0;
                }
                img {
                    //height: 100%;
                    max-height: 4rem;
                }
            }
            
            h1 {
                @include mq(715px){
                    padding-left: 2rem;
                }
            }
        }
        .buttons-wrapper {
            // display: inline-flex;
            // align-items: center;
            button:nth-of-type(2) {
                margin: 1rem 0 0;
                @include mq(470px){
                    margin: 0 0 0 .2rem;
                }
                @include mq(480px){
                    margin: 0 0 0 1rem;
                }
            }
        }
    }

    .info-bar {
        display: inline-flex;
        flex-direction: column-reverse;
        width: 100%;
        color: $gray;
        padding: 1rem 0;
        @include mq(715px){
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
			min-height: 3rem;
        }
        // border-bottom: .15em solid $silver;
        .count-and-filters {
            display: inline-flex;
            align-items: center;
            .record-count {
                align-items: center;
                width: 18.5rem;
                @include mq(715px){
                    border-right: .15em solid $silver;
                }
                p {
                    padding: .5rem 0 0 0;
                    @include mq(715px){
                        padding:0 0 0 2rem;
                    }
                }
            }
			.reset-columns{
				// display: inline-flex;
				// align-items: start;
				margin-left: 1rem;
				.button-light{
					font-size: 1rem;
					padding: .5rem 1rem;
				}
			}
            .filters {
                display: inline-flex;
                align-items: center;
                p {
                    margin-left: 1rem;
                    color: $gray;
                    padding: 0.25em 0.75em;
                }
            }
        }
        .location-and-sort {
            display: inline-flex;
            align-items: center;
			padding-right: .5rem;
            .dropdown-container {
                .dropdown-toggle {
                    display: inline-flex;
                    align-items: center;
                    color: $gray;
                    p {
                        padding-left: 1rem;
                        padding-right: 0.25rem;
                    }
                }
            }
        }
    }

    .searchbar {
        position: relative;
        &:before {
            content: "";
            background-image: url("../../images/search-gray.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
            width: 1.2em;
            height: 1.2em;
            position: absolute;
            right: 2em;
            top: .9em;
            z-index: 1;
        }
        p {
            margin-left: 1em;
        }
        input {
            padding: .7em 1.5em;
            box-sizing: border-box;
            width: 100%;
            background-color: $offwhite;
            border: .15em solid $offwhite;
            position: relative;
            color: $gray;
            font-size: 1.1rem;
            &:focus-visible {
                outline: unset;
                border: .15em solid $midnight;
                border-radius: .15em;
            }
            &::placeholder {
                color: $gray;
                font-size: 1rem;
            }
            
        }
    }
}
//$drawer-width: 200px !important;
$drawer-tab-width: 10px;

$drawer-bg-color: $drawer_gray;
$drawer-tab-bg-color: $gray;

.drawer {
    width: 375px;
    background: $drawer-bg-color;
    height: 100%;
    transition: transform $transition-duration ease-in-out;
    position: absolute;
    transform: translateX(-100%);
    box-sizing: border-box;
    left: 0;
    top: 0;
    z-index: 2;
    &.open {
        transform: translateX(0);
        left: 0;
    }

    .drawer-tab {
        width: $drawer-tab-width;
        height: 70px;
        background: $drawer-tab-bg-color;
        position: absolute;
        z-index: 10;
        top: 2.9em;
        right: -10px; // Align the tab to the edge of the drawer
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-top: 15px solid $drawer_gray;
        border-right: 5px solid $drawer_gray;
        border-bottom: 15px solid $drawer_gray;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .drawer-content {
        padding: 1.5em .6em 2em;
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        background-color: $drawer-bg-color;
        @include mq(410px){
            padding: 1.5em 2em 2em;
        }
        .drawer-title {
            text-transform: uppercase;
            color: $white;
            padding-bottom: 0.7rem;
            font-weight: 600;
        }
        .clear-filters {
            cursor: pointer;
            color: $gray;
            text-transform: uppercase;
            text-decoration: underline;
        }
        .drawer-section {
            &:not(:first-child) {
                border-top: 1px solid $gray;
                margin-top: 1.8rem;
                padding-top: 1.8rem;
                display: grid;
            }
            label {
                margin-bottom: 0.4em;
                text-transform: uppercase;
                &:before {
                    border-radius: 400px;
                }
            }
            input[type=checkbox],
            input[type=radio] {
                cursor: pointer;
                margin-right: 1.3em;
				appearance: none; 
    			-webkit-appearance: none;
                &::before {
                    content: "\202A";
                    background: $drawer-bg-color;
                    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
                    font-size: 23px;
                    text-align: center;
                    line-height: 18px;
                    display: inline-block;
                    width: .7em;
                    height: .7em;
                    border: 1px solid $gray;
                    border-radius: 2px;
                    margin: -.1em;
                    text-indent: 1px;
                }
                &:checked:before {
                    content: "";
                    background: $white;
                    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
                    font-size: 24px;
                    text-align: center;
                    line-height: 18px;
                    display: inline-block;
                    width: .7em;
                    height: .7em;
                    border: 1px solid $gray;
                    border-radius: 2px;
                    margin: -.05em -.1em;
                    text-indent: 1px;
                }
            }
        }
        .show-more-btn {
            background-color: unset;
            border: unset;
            padding: unset;
            color: $white;
            font-size: 1.2rem;
            text-transform: uppercase;
            cursor: pointer;
            margin: 1em 0 0 1.6em;
            @include transition(500ms);
            &:hover {
                opacity: .6;
                @include transition(500ms);
            }
            &:after {
                content: "+";
                margin-left: .3em;
            }
        }
        .training-date-wrapper {
            display: flex;
            justify-content: space-between;
            .training-date {
                display: flex;
                flex-direction: column;
                width: calc(50% - .3em);
                text-transform: capitalize;
                input[type="date"] {
                    background-color: $midnight;
                    border: unset;
                    border-radius: .3em;
                    margin: .4em 0 0;
                    cursor: pointer;
                    &:focus-visible {
                        outline: .15em solid $white;
                    }
                }
                ::-webkit-datetime-edit { padding: 1em .1em 1em 1em; }
                ::-webkit-datetime-edit-fields-wrapper { background: $midnight; }
                ::-webkit-datetime-edit-text { color: $gray; }
                ::-webkit-datetime-edit-month-field { color: $gray; }
                ::-webkit-datetime-edit-day-field { color: $gray; }
                ::-webkit-datetime-edit-year-field { color: $gray; }
                ::-webkit-datetime-edit-day-field:focus,
                ::-webkit-datetime-edit-month-field:focus,
                ::-webkit-datetime-edit-year-field:focus {
                    background-color: $silver;
                    color: white;
                    outline: none;
                }
                ::-webkit-calendar-picker-indicator { 
                    filter: invert(.6); 
                    margin-right: 1em; 
                    font-size: 18px;
                    cursor: pointer; 
                }
            }
        }
        .button {
            background-color: unset;
            margin-top: 1.5em;
            width: 100%;
            @include transition(500ms);
            &:hover {
                border-color: $white;
                @include transition(500ms);
            }
        }
    }
}

// Table
.grid-container,
.card-list-wrapper {
    overflow: auto;
    .InovuaReactDataGrid {
        min-width: 90em;
    }
    .InovuaReactDataGrid--theme-default-light {
        border: .15em solid $silver !important;
        color: $gray !important;
        margin-bottom: 1em;
        .InovuaReactDataGrid__cell__content {
            span {
				&.green{
					color: #04AA6D !important;
				}
				&.red{
					color: #FF0000 !important;
				}
				
				
            }
        }
        .InovuaReactDataGrid__header-group__title {
            border-bottom: .15em solid $silver !important;
            color: $gray;
        }
		.InovuaReactDataGrid__column-header__menu-tool{
			display: none;
		}
        .InovuaReactDataGrid__column-header--show-border-left,
        .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left,
        .InovuaReactDataGrid__header-group__title--show-border-left {
            border-left: .15em solid $silver !important;
            color: $gray;
        }
        .InovuaReactDataGrid__cell--show-border-bottom,
        .InovuaReactDataGrid__footer-row-cell--show-border-bottom,
        .InovuaReactDataGrid__locked-row-cell--show-border-bottom {
            border-bottom: .15em solid $silver !important;
        }
        .InovuaReactDataGrid__cell--show-border-left,
        .InovuaReactDataGrid__footer-row-cell--show-border-left,
        .InovuaReactDataGrid__locked-row-cell--show-border-left {
            border-left: .15em solid $silver !important;
        }
        .InovuaReactDataGrid__row--first {
            .InovuaReactDataGrid__cell.InovuaReactDataGrid__cell,
            .InovuaReactDataGrid__nested-block,
            .InovuaReactDataGrid__nested-cell,
            .InovuaReactDataGrid__nested-group-cell {
                border-top: .15em solid $silver !important;
            }
        } 
    }
}