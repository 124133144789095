@import "../global-styles";

form {
    font-family: 'Roboto', sans-serif;
    font-size: 1.125rem;
    color: $gray;
    .row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .quarter-width,
        .half-width,
        .full-width {
            margin-bottom: 1.1em;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        .quarter-width{
            @include mq(550px){
                width: calc(25% - 8px);
            }
        }
        .half-width {
            @include mq(550px){
                width: calc(50% - 8px);
            }
        }

        label{
            text-transform: capitalize;
            margin-bottom: .3em;
        }
        input,
        select,
        textarea {
            box-sizing: border-box;
            background-color: $offwhite;
            border-radius: 5px;
            border: .15em solid $silver;
            padding: .9rem;
            margin-top: .5rem;
            font-size: 16px;
            color: $gray;
            &:focus-visible{
                border: .15em solid $midnight;
                outline: none;
            }
            &::placeholder{
                color: $gray;
                margin-bottom: .25em;
            }
        }
        textarea{
            min-height: 5rem;
        }
		input[type="time"] {
			padding: .8rem;
		}

        .has-error{
            input, 
            select,
            textarea{
                border: 1px solid $red;
                outline: none;
            }
        }
		
    }
	.border-top{
		border-top: 2px solid #dddddd;
		margin-top: 1.5rem;
		padding-top: 2rem;
	}
    .form-bottom-button-wrapper {
        display: flex;
        justify-content: flex-start;
        margin-top: 2em;
        button:nth-child(n+2){
            margin-left: 2em;
        }
    }
    .logo-upload-wrapper{
        // margin-top: .5rem;
        // border-top: 2px solid $silver;
        // padding-top: 1.25rem;
        input[type="file"] {
            display: none;
        }
    }
    .custom-file-upload {
        padding: 0.625rem 1.875rem;
        border-radius: 5px;
        border: .15em solid $silver;
        background-color: none;
        color: $gray;
        display: inline-flex;
        align-items: center; 
        gap: 5px; 
        cursor: pointer;
        margin-bottom: 1.4em;
        @include transition(500ms);
        &:hover {
            background-color: $gray;
            color: $white;
            border: .15em solid $gray;
            @include transition(500ms);
        }
        img {
            width: 1em;
        }
      }
      
      .file-info {
        display: inline-block;
        margin-left: 1em;
        vertical-align: top;
        span {
            font-weight: 600;
        }
      }
      
      .file-info small {
        display: block;
      }
      .file-name {
        display: none;
      }
      .button-blue {
        margin: 1em 0;
      }

	  .select-readonly {
		background-color: #e9ecef !important; // Light grey background, similar to the disabled state
		//color: #495057 !important; // Dark grey text color
		pointer-events: none; // Prevents the user from clicking on the select
	  
		&:focus {
		  outline: none; // Removes the focus outline
		}
	  }

	  .hide{
		display: none !important;
	  }
	  
}



 // add checkin form popup
 .add-visitor-form-bottom-wrapper{
    min-height: 234px;
    .status-message{
        color: $indigo;
        margin-bottom: 1rem;
    }
    .error-message{
        color: $red;
    }
 }



 


.automatic-message-creator{
	.selected-message{
		label{
			margin-bottom: .55em;
		}
	}
	.message-active-row{
		justify-content: start;
		padding-bottom: 1.5rem;
		//padding: 1.5rem 0;
		input{
			margin-top: 0;
		}
		label{
			margin-bottom: 0;
			padding-left: .5rem;
		}
	}
	.add-trainees-list{
		border-top: 2px solid #dddddd;
		margin-top: 2rem;
		padding-top: 1.5rem;
		padding-bottom: 2rem;
		h3{
			margin-bottom: 1rem;
		}
	}
}


.message-sender{
	.selected-message{
		label{
			margin-bottom: .55em;
		}
	}
	.add-trainees-list{
		border-top: 2px solid #dddddd;
		margin-top: 2rem;
		padding-top: 1.5rem;
		padding-bottom: 2rem;
		h3{
			margin-bottom: 1rem;
		}
	}
}

.message-table {
    width: 100%;
    border-collapse: collapse; 

    th, td {
        min-width: 150px; 
        padding: 8px;
        border-bottom: 1px solid #dddddd; 
    }

    thead {
        .table-header-row {
            background-color: #f2f2f2; 
        }
    }

    tbody {
        .table-row {
            &:nth-child(even) {
                background-color: #f9f9f9; 
            }
        }
    }
}


.notification-options, .video-section{
	.radio-options{
		display: flex;
		gap: 1rem;
	}
}

.notification-numbers{
	.phone-number{
		display: flex;
		justify-content: space-between;
		margin-bottom: .5rem;
		align-items: center;
		.button-gray{
			margin-top: 0;
			padding: .5rem;
	
		}
	}
	.add-phone-number{
		display: flex;
		justify-content: space-between;
		align-items: center; 
		input {
            flex: 1; 
            margin-right: .5rem; 
            height: auto; 
        }
		.button-blue{
			margin-top: 1.65rem;
		}
		
	}
	
}


.language-table{
	width: 100%;
	border-collapse: collapse; 

	th, td {
		min-width: 150px; 
		padding: 8px;
		border-bottom: 1px solid #dddddd; 
		&:last-child{
			border-bottom: none; 
		}
	}

	tbody {
		.table-row {
			&:nth-child(even) {
				background-color: #f9f9f9; 
			}
			vertical-align: middle;
		}
		tr:last-child td {
			border-bottom: none;
		  }
	}
	input,
	select,
	textarea {
		box-sizing: border-box;
		background-color: $offwhite;
		border-radius: 5px;
		border: .15em solid $silver;
		padding: .9rem;
		margin-top: .5rem;
		font-size: 16px;
		color: $gray;
		&:focus-visible{
			border: .15em solid $midnight;
			outline: none;
		}
		&::placeholder{
			color: $gray;
			margin-bottom: .25em;
		}
	}
	button{
		margin-top: .5rem !important;
		
	}
	.bump-down{
		margin-top: 1.5rem !important;
	}

}


.manual-training-file-upload{
	padding-bottom: 1rem;
	label{
		padding-right: .75rem;
	}
}