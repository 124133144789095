@import "../global-styles";

.dashboard-wrapper{
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 2rem 5vw;
    z-index: 2;
    // overflow: hidden;
    // position: relative;
    // top: -17em;

    .dashboard-top{
        
        color: $silver;
        overflow: hidden;
        padding-bottom: 7em;
        @include mq(950px){
            margin-right: 41%;
            padding-bottom: 3.3em;
        }
        @include mq(1145px){
            margin-right: 35%;
        }
        .heading-container{
            display: inline-flex;
            align-items: center; 
            .letters-circle{
                margin-left: 0;
                font-weight: 600;
                height: 2em;
                width: 2em;
                font-size: 1.4rem;
            }
            h3{
                color: $white;
            }
        }
        .text-container{
            margin: .75rem 0 1.5rem 0;
        }
        .button-container{
            display: flex;
            overflow-x: auto;
            padding-bottom: 1.4em;
            .button-gray{
				padding: 0.5rem 1.25rem;
				border-radius: 5px;
                text-align: center;
                position: relative;
                img{
                    height: 1em;
                    margin-right: .5em;
                    filter: invert(1);
                }
            }
            gap: 1.5rem;
        }
    } 

    .dashboard-bottom{
        color: $gray;
        @include mq(950px){
            margin-right: 41%;
        }
        @include mq(1145px){
            margin-right: 35%
        }
        // height: calc(100% - $black_background_height);
        // overflow: hidden;
        // overflow-y: scroll;
        .heading-container{
            margin-top: 1rem;
        }
        .card-container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between; 
            margin-top: 2vh;
            .action-card{
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 1.5rem 1rem;
                margin-bottom: 1rem;
                text-align: center;
                border: .15em solid $silver;
                border-radius: 5px;
                box-shadow: $box_shadow;
                text-decoration: none;
                background-color: white;
                cursor: pointer;
                @include mq(475px){
                    flex-basis: calc(50% - (1rem / 2));
                }
                img{
                    height: 3rem;
                }
                h3{
                    color: $indigo;
                    padding: .5em 0;
                }
                &:hover{
                    border: 1.5px solid $indigo;
                }
            }
        }
    }

    .dashboard-list{
        display: none;
        @include mq(950px){
            display: block;
            position: absolute;
            right: 5vw;
            top: 2rem;
            max-height: calc(100vh - 4rem);
        }
        .list-switch{
            color: $white;   
            display: flex;
            width: 100%;
            justify-content: center;
            //border-bottom: 2px solid $white;
            margin-bottom: .75em;
            .list-option{
                cursor: pointer;
                padding: .5em 1em;
                border-bottom: .4em solid $gray;
                color: $white;
                font-size: 1.1rem;
                &.active{
                    border-bottom: .4em solid $white;
                }
            }
        }
        .list-container{
            background-color: $white;
            border: .15em solid $silver;
            border-top: 1.5em solid $white;
            border-radius: 5px;
            box-shadow: $box_shadow;
            height: 73vh;
            max-width: 26.5vw;
            min-width: 26em;
            overflow-y: scroll;
            font-size: .75em;
            padding: 0 1em 1em;
            color: $gray;
            .list-day{
                text-align: center;
                padding: 1.2em;
                font-weight: 600;
                font-size: .9rem;
            }
            .letters-circle{
                background-color: $gray;
                color: $white;
                font-size: 1em;
                height: 2em;
                width: 2em;
                margin-left: 0;
                margin-right: 0;
            }
            table{
                border-collapse: collapse;
                border-spacing: 0;
                table-layout: fixed;
                width: 100%;
                text-align: left;
                margin-bottom: 3.125em;
            }
            td{
                text-align: left;
                margin: 0;
                overflow: auto;
                padding: .7em;
				// &:nth-child(1) { 
				// 	width: 2em; 
				// }
                &:nth-child(4) { text-align: right; }
                &.third-td {
                    width: 5.5em;
                }
            }
            tr:nth-child(even) {
                background-color: $offwhite;
            }
            .filter {
                background: linear-gradient(to top, white, transparent);
                width: 100%;
                height: 7em;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        } 
        ::-webkit-scrollbar {
            height: 0.3em;
        }
    }
}





