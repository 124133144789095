/*------------------------------------*\
    Mixins
\*------------------------------------*/
// Translate. Pass in x and y value for positioning
@mixin translate($x, $y) {
    -moz-transform: translateX($x) translateY($y);
    -webkit-transform: translateX($x) translateY($y);
    -o-transform: translateX($x) translateY($y);
    -ms-transform: translateX($x) translateY($y);
    transform: translateX($x) translateY($y);
}
//Transitions. Pass in animation duration
@mixin transition($duration) {
    -webkit-transition: all $duration cubic-bezier(0.455, 0.03, 0, 1);
    -moz-transition: all $duration cubic-bezier(0.455, 0.03, 0, 1);
    -o-transition: all $duration cubic-bezier(0.455, 0.03, 0, 1);
    transition: all $duration cubic-bezier(0.455, 0.03, 0, 1);
}
// Media Query
@mixin mq($width) {
    @media only screen and (min-width: $width) {
        @content;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*------------------------------------*\
    Fonts
\*------------------------------------*/
/* roboto reg and bold | regular used for body text | bold used for h3, h4 */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

/* this is used for h1, h2 */
@font-face {
    font-family: "avenir-next";
    src: url(../fonts/Avenir-Next-LT-Pro-Bold.otf);
}
/*------------------------------------*\
    Colors
\*------------------------------------*/
$white: #ffffff;
$red: #c95e5e; // for errors
$midnight: #15202e;
$gold: #f0ad2e;
$indigo: #6d90bf;
$indigo_hover: rgba(109, 144, 191, 0.8);
$darksea: #363f4b;
$gray: #aaaaaa;
$silver: #dddddd;
$offwhite: #f5f5f5;
$nobel: #989898; // color for form labels but using gray instead for now
$drawer_gray: #303741;

/*------------------------------------*\
    Reset
\*------------------------------------*/
html {
    font-size: 16px; /* This is a common default for many browsers */
    font-family: "Roboto", sans-serif;
    box-sizing: border-box;
    /* 
    Use below once frontend is all worked out to prevent movement
    height: 100vh;
    width: 100vw;
    // overflow: hidden;
    */
}
body {
    overflow-x: hidden !important;
    margin: 0;
    overflow: scroll;
    ::-moz-selection {
        color: $white;
        background: $silver;
    }
    ::selection {
        color: $white;
        background: $silver;
    }
}
* {
    margin: unset;
}

/*------------------------------------*\
    Font Sizes and Colors
\*------------------------------------*/
h1 {
    font-size: 1.8rem;
    font-family: "avenir-next", sans-serif;
    color: $darksea;
    @include mq(715px){
        font-size: 2.2rem
    }
}
h2 {
    font-size: 1.5rem;
    font-family: "avenir-next", sans-serif;
    color: $darksea;
    @include mq(715px){
        font-size: 2rem;
    }
}
h3 {
    font-size: 1.3125rem;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: $gray;
}
h4 {
    font-size: 1.125rem;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: $gray;
}
p {
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    color: $gray;
}

/*------------------------------------*\
    Scrollbars
\*------------------------------------*/
::-webkit-scrollbar {
    width: 0.8em;
    height: 0.6em;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: $gray;
    border-radius: 0.5em;
}

/*------------------------------------*\
  Global Styles
\*------------------------------------*/
.bg-topo-dark {
	background: url('../images/topo-line-art.png') no-repeat center center fixed,
	  $darksea;
	background-size: cover;
  }
.bg-topo-light {
	background: url('../images/topo-line-art.png') no-repeat center center fixed,
		$white;
	background-size: cover;
} // add new pointer
.fullscreen {
	display: flex;          
    justify-content: center;  
    align-items: center; 
	min-height: 100vh;
	min-width: 100vw;
  }
// height of the navigation | needed in calculations
$top_nav_height: 4.5rem;

// standard box shadow
$box_shadow: 1px 1px 2px $silver;

// height of the black background. % of the hight minus the nav
$black_background_height: 27%;

// white circle with two letters in it
.letters-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    color: $gray;
    text-transform: uppercase;
    background-color: $white;
    border-radius: 50%;
    margin-right: 0.5em;
    margin-left: 1em;
}

// main container adjusting for nav and letting a card to be centered
.main-container {
    position: fixed;
    z-index: 2;
    top: 0;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    padding-top: 3em;
    @media screen and (min-height: 900px) {
        height: calc(100vh - $top_nav_height);
      }
    @include mq(950px){
        width: 100vw;

    }
}

// setting the black background that is the top part of the background
.main-container-background-dark {
    .dark-bg-img-wrapper {
        background-color: $midnight;
        height: 26em;
        width: 100vw;
        position: absolute;
        top: 4.5em;
        @include mq(450px) {
            height: 24.5em;
        }
        @include mq(550px) {
            height: 23em;
        }
        @include mq(650px) {
            height: 21em;
        }
        @include mq(900px) {
            height: 20em;
        }
        @include mq(1075px) {
            height: 18.5em;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

/*------------------------------------*\
  Random Piece's Styles
\*------------------------------------*/

// alerts styling
.alert {
    z-index: 9999;
    position: absolute;
    top: 2rem;
    right: 2rem;
    height: 2.5rem;
    min-width: 15rem;
    max-width: 20rem;
    padding: 1em;
    border-radius: 6px;
    background-color: $white;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: capitalize;
    img {
        height: 100%;
        margin-right: 1rem;
    }
}

.loading-icon{
    width: 1.5rem;
    height: 1.5rem;
    animation: rotate 1s linear infinite;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modal-content {
    position: relative;
    background: white;
    padding: 3em 2em 2em;
    max-height: 80vh;
    max-width: 1200px;
    overflow-y: scroll;
    width: 90%;
    box-sizing: border-box;
    border-radius: 6px;
    .modal-close {
        position: absolute;
        top: 1rem;
        right: 1.3rem;
    }
    .button-blue {
        margin-top: 1em;
    }
}

.pen-icon,
.delete-icon,
.pdf-icon {
    height: 1.25em;
    width: 1.25em;
    &:hover {
        cursor: pointer;
    }
}

.prevent-scroll {
    overflow: hidden;
    //return to the top of that page
    position: fixed;
}

.gray-filter {
    filter: brightness(0) saturate(100%) invert(78%) sepia(10%) saturate(198%) hue-rotate(160deg) brightness(86%) contrast(86%);
}

.location-selection{
	text-align: center;
	max-width: 900px;
	
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex; 
        flex-direction: column; 
        li{
            margin-top: 1rem;
            button {
                flex: 1;
                width: 100%;
            }
        }
    }
    
}

// center grid headers
.text-center {
    text-align: center;
}

.mr-1{
    margin-right: 1rem;

}
.mb-1{
    margin-bottom: 1rem;

}
.flex{
    display: flex;
}
.flex-start{
    align-items: flex-start;
    justify-content: flex-start;
}
.flex-center-start{
    align-items: center;
    justify-content: flex-start;
}

.column{
    flex-direction: column;
}

.flex-center{
    justify-content: center;
    align-items: center;
}

.flex-wrap{
    flex-wrap: wrap;

}

.gap-1{
    gap: 1rem;
}
.gap-2{
    gap: 2rem;
}
.gap-3{
    gap: 3rem;
}

.disabled{
    pointer-events: none;
    opacity: 0.5;
}
.hidden{
    display: none;
}

.self-center{
    align-self: center;
}  

.self-start{
    align-self: start;
}   


.safety-green{
    filter: brightness(0) saturate(100%) invert(55%) sepia(62%) saturate(419%) hue-rotate(73deg) brightness(93%) contrast(91%);

}

.safety-red{
    filter: brightness(0) saturate(100%) invert(47%) sepia(85%) saturate(451%) hue-rotate(314deg) brightness(86%) contrast(81%);
}

.safety-blue{
    filter: brightness(0) saturate(100%) invert(59%) sepia(47%) saturate(366%) hue-rotate(175deg) brightness(85%) contrast(87%);
}

.icon{
    width: 1.5rem;
    height: 1.5rem;
}

.input-icon{
    width: 1.5rem;
    height: 1.5rem;
}

.input-icon:hover{
    transform: scale(1.1);
}
.large-text-input {
    width: 100%;
    max-width: 600px; /* Adjust the max width as needed */
    min-height: 150px; /* Adjust the min height as needed */
    padding: 10px;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical; /* Allows the user to resize the height of the textarea */
    box-sizing: border-box; /* Ensures padding and border are included in the width and height */
    overflow-wrap: break-word; /* Breaks long words to fit within the textarea */
    white-space: pre-wrap; /* Preserves white space and breaks lines as necessary */
  }
  
.list-box{
    border: 1px solid silver;
    border-radius: 6px;
    padding: 1rem;
    background-color: white;
    list-style-type: none;

}


.agreement-box {
    padding: 1rem;
    position: relative;
    box-sizing: border-box;
    display: block;
    width: 100%;
    color: $nobel;
    background-color: $offwhite;
    border: 1px solid $silver;
    border-radius: 6px;
    cursor: default !important;
    margin-top: 1rem;
    p {
        font-weight: 700;
    }
    .move-icon {
        position: absolute;
        right: 1em;
        top: 1em;
        width: 20px;
        text-align: center;
        &:hover {
            cursor: grab;
        }
        span {
            display: block;
            height: 2px;
            background: $gray;
            width: 100%;
            margin: 0 0 3px 0;
        }
    }
    input,
    textarea {
        width: 100%;
        width: calc(100% - 2.5rem);
        border: 0.15em solid $silver;
        border-radius: 6px;
        color: $nobel;
        padding: 0.75rem;
        margin: 1rem 0;
        &::placeholder {
            color: $gray;
        }
        &:focus-visible {
            border: 0.15em solid $midnight;
            outline: none;
        }
    }
    textarea {
        margin-bottom: 2rem;
        height: 5em;
        overflow-y: auto;
        resize: none;
    }

    .remove-icon {
        position: absolute;
        right: 0.5em;
        bottom: 0.5em;
        width: 15px;
        height: 15px;
        text-align: center;
        &:hover {
            cursor: pointer;
            span {
                background-color: $red;
            }
        }
        span {
            display: block;
            position: absolute;
            height: 2px;
            background: $gray;
            width: 100%;
            top: 50%;
            left: 0;
            transform-origin: center;
            &:first-child {
                transform: rotate(45deg);
            }
            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }
}

.add-new-agreement-button {
    display: flex; // Use flexbox for layout
    align-items: center; // Vertically center items
    justify-content: center; // Horizontally center content
    gap: 0.5rem; // Spacing between text and icon

    width: 100%;
    padding: 0.75rem 0;
    color: $gray;
    background-color: $offwhite;
    border: 1px solid $silver;
    margin-top: 1rem;
    border-radius: 6px;

    &:hover {
        cursor: pointer;
    }

    p {
        margin: 0; // Remove default paragraph margins
    }

    .add-icon {
        width: 12.5px;
        height: 12.5px;
        position: relative; // Needed for absolute positioning of spans

        span {
            display: block;
            position: absolute; // Absolute positioning within add-icon
            background: $gray;
            width: 2px;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);

            &:last-child {
                width: 100%;
                height: 2px;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
        }
    }
}
.radio-buttons-section {
    display: inline-flex;
    gap: .5em;
    margin-bottom: 1rem;
    flex-direction: column;
    @include mq(650px){
        flex-direction: row;
        gap: 3em;
    }
    label {
        input {
            margin-right: 0.5em;
        }
    }
}


.delete-item-text-wrapper{
	margin-top: 1rem;
	h3, p{
		margin-top:.5rem
	}
	h2, h3, p{
		span{
			color: $indigo;
		}
	}
	
}

.message-delete-confirm{
	text-align: center;
	.button-wrapper{
		display: flex;
		justify-content: center;
		gap: 1rem;
	}
}


.location-url-row{
	border-top: 2px solid #dddddd;
	margin-top: 1.5rem;
	padding-top: 2rem;
	padding-bottom: 5rem;
}

body:has(.body-decorator) {
	@import "~@aws-amplify/ui-react/styles.css";

    align-self: center;
    justify-self: center;
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	background: url('../images/topo-line-art.png') no-repeat center center fixed,
	  #15202e;
	background-size: cover;

	// Add the image at the top
	form[data-amplify-form] {
		&::before {
			content: "";
			display: block;
			width: 400px;
			height: 100px; 
			background-image: url('../images/tervuren-logo-color-lg.png'); 
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			margin: 0 auto 1.5rem; 
		}
	}
}
  
.amplify-button--primary{
    background-color: $gold !important;
    border: 2px solid $gold !important;
	color: $midnight !important;
}
.amplify-button--small{
    color: $gold !important; 
	color: $midnight !important;
}


