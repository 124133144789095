@import "../global-styles";
@import "../components/buttons";

// Timing
$container-rotate-timing: 1250ms;
$rotate-timing: 5000ms;
$spinner-line-count: 4;
$spinner-left-spin:  $rotate-timing / $spinner-line-count;
$spinner-right-spin: $rotate-timing / $spinner-line-count;


@mixin animate($animation,$duration,$method,$times){
    animation: $animation $duration $method $times;
}

@mixin keyframes($name){
    @keyframes #{$name}{
        @content;
    }
}


/*-----------------------------------*\
    Animations
\*------------------------------------*/
@keyframes container-rotate {
    to { transform: rotate(360deg); }
  }
  @keyframes fill-unfill-rotate {
    12.5% { transform: rotate(135deg); }
    25%   { transform: rotate(270deg); }
    37.5% { transform: rotate(405deg); }
    50%   { transform: rotate(540deg); }
    62.5% { transform: rotate(675deg); }
    75%   { transform: rotate(810deg); }
    87.5% { transform: rotate(945deg); }
    to    { transform: rotate(1080deg);}
  }
  @keyframes left-spin {
    0%  { transform: rotate(130deg); }
    50% { transform: rotate(-5deg); }
    to  { transform: rotate(130deg); }
  }
  @keyframes right-spin {
    0%  { transform: rotate(-130deg); }
    50% { transform: rotate(5deg); }
    to  { transform: rotate(-130deg); }
  }
  @keyframes line-1-fade-in-out{
    0%   { opacity:1 }
    100% { opacity:1 }
  }

.loader-overlay{
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0,0,0,.25);
    background-size:cover;
    background-position: left;
    z-index: 100;

    .inner-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50%;
        width: 50%;
        margin: auto;
        text-align: center;
        .terv-logo {
            width: 20em;
            height: auto;
            margin-bottom: 3em;
        }
        .button-wrapper {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            gap: 15px;
        }
        .spinner-wrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            .spinner {
                height: 130px;
                width: 130px;
                /* Spins external container */
                animation: container-rotate #{$container-rotate-timing} linear infinite;
                .spinner-line {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  border-color: $white;
                  animation: fill-unfill-rotate #{$rotate-timing} cubic-bezier(0.4, 0, 0.2, 1) infinite both, line-1-fade-in-out #{$rotate-timing} cubic-bezier(0.4, 0, 0.2, 1) infinite both;
                  .spinner-line-cog {
                    display: inline-block;
                    position: relative;
                    width: 50%;
                    height: 100%;
                    overflow: hidden;
                    border-color: inherit;
                    .spinner-line-cog-inner {
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      right: 0;
                      left: 0;
                      width: 200%;
                      box-sizing: border-box;
                      height: 100%;
                      //border: 7px solid $black;
                      border: 7px solid $white;
                      // border: double 17px $black;
                      // background-image: linear-gradient(white, white), radial-gradient(circle at top left, $white,#333);
                      background-origin: border-box;
                      background-clip: content-box, border-box;
                      border-radius: 50%;
                      border-bottom-color: transparent;
                      animation: none;
                      &.spinner-line-cog-inner-left {
                        border-right-color: transparent;
                        transform: rotate(129deg);
                        animation: left-spin #{$spinner-left-spin} cubic-bezier(0.4, 0, 0.2, 1) infinite both;
                      }
                      &.spinner-line-cog-inner-right {
                        left: -100%;
                        border-left-color: transparent;
                        transform: rotate(-129deg);
                        animation: right-spin #{$spinner-right-spin} cubic-bezier(0.4, 0, 0.2, 1) infinite both;
                      }
                      &.spinner-line-cog-inner-center {
                        width: 1000%;
                        left: -450%;
                      }
                    }
                  }
                  .spinner-line-ticker {
                    position: absolute;
                    box-sizing: border-box;
                    top: 0;
                    left: 45%;
                    width: 10%;
                    height: 100%;
                    overflow: hidden;
                    border-color: inherit;
                  }
                }
              }
        }
          .loader-center {
            width: 70px;
            height: 70px;
            position: absolute;
            left: 50%;
            top: 50%;
            @include translate(-50%,-50%);
            #countdown-timer {
              position: absolute;
              top: 50%;
              left: 50%;
              @include translate(-50%,-50%);
              z-index: 1;
              font-size: 35px;
              color: $white;
            }
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
              left: 50%;
              top: 50%;
              @include translate(-50%,-50%);
              position: absolute;
            }
          }

    }
  }
