@import "../global-styles";

.top-nav{
    width: 100vw;
    height: $top_nav_height;
    display: flex;
    align-items: center;  
    justify-content: center;  
    background-color: $midnight;
    color:  $offwhite;
    .tervuren-logo{
        width: 10em;
        position: absolute;
        left: 5vw;
        // @include mq(1145px){
        //     left: 1em;
        // }
    }
    .nav-list{
        display: none;
        @include mq(1150px){
            width: 40vw;
        display:  inline-flex;
        justify-content: space-between;
        list-style: none;
        padding: unset;
        }
        @include mq(1300px){
            width: 35vw;
        }
        a{
            color: $offwhite;
            text-decoration: none;
        }
    }
    .user-dropdown{
        position: absolute;
        right: 3em;
        text-align: right;
        display: inline-flex;
        align-items: center; 
        .user-info {
            display: none;
            @include mq(700px){
                display: block;
            }
            .welcome{
                color: $white;
            }
        }
    }
}


/*------------------------------------*\
  Dropdown Styles
\*------------------------------------*/

.dropdown-container {
    .dropdown-toggle{
        cursor: pointer;
        .arrow {
            position: absolute;
            right: -1.5em;
            top: .7em;
            width: 0; 
            height: 0; 
            border-left: .5em solid transparent;
            border-right: .5em solid transparent;
            @include transition(500ms);
            @include mq(700px){
                top: 1em;
            }
            &.up {
                border-bottom: .8em solid $white;
                @include transition(500ms);
            }
            &.down {
                border-top: .8em solid $white;
                @include transition(500ms);
            }
        }
    }
    .dropdown-menu {
        position: fixed;
        z-index: 100;
        top: calc($top_nav_height + .5rem );
        right: 1rem;
        min-width: 16rem;
        max-height: 0; // initial closed state
        overflow: hidden;
        color: $gray;
        text-align: left;
        background-color: #F3F3F3;
        width: 20.5em;
        // padding: .5rem;
        // border: 1px solid $silver;
        border-radius: 1px;
        box-shadow: $box_shadow;
        @include transition(500ms);
        &.open {
            max-height: 500px; // open state
            z-index: 100;
            @include transition(500ms);
        }

        .menu-top{
            // width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 1em 1em 0.5em;
            font-weight: 600;
            .letters-circle{
                width: 1.5rem; 
                height: 1.5rem; 
                font-size: .75rem; 
                font-weight: 400;
                background-color: $gray;
                color: $white;
                margin: 0 .5em 0 0;
            }
            img {
                width: 1.1em;
                height: 1.1em;
                object-fit: contain;
                margin: 0 1em 0 auto;
                filter: brightness(.6);
                cursor: pointer;
            }
        }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
                padding: .75em 1.3em;
                cursor: pointer;
                color: $gray;
                @include transition(500ms);
                &:hover {
                    background-color: $indigo;
                    @include transition(500ms);
                    a {
                        color: $white;
                    }
                }
                a{
                    text-decoration: none;
                    color: $gray;
                    @include transition(500ms);
                    // below display and height allow the li containg the link to be clicked
                    display: block; 
                    height: 100%;
                    
                }
            }
        }

        .menu-bottom{
            width: 100%;
            margin: .9rem 0 .5rem 0;
            padding-top: .8em;
            // [start] make the line in between
            background-image: linear-gradient(to right, transparent 5%, $gray 5%, $gray 95%, transparent 95%);
            background-repeat: no-repeat;
            background-size: 100% .15em;  // this makes sure the border is only .15em
            background-position: top center; 
            // [end] line up top 
            a{
                text-decoration: none;
                display: block;
                // width: 100%;
                padding: 1em 1.3em;
                color: $gray;
                cursor: pointer;
                @include transition(500ms);
                &:hover {
                    color: $white;
                    background-color: $indigo;
                    @include transition(500ms);
                }
            }
        }

    }
}
// we ad
.global-nav-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
// I probably need to get a design from the designer for this
.tervuren-notification-banner{
    // display: flex;
    width: 100%;
    height: 2rem;
    background-color: $white;
    color: black;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    display: flex;
    position: relative;
    z-index: 100000;
}

// create a side box on the right for notifications 
.notification-count{
    position: absolute;
    right: 0;
    top: 0;
    width: 2em;
    height: 2em;
    background-color: $indigo;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 1em;
    font-weight: 600;
    z-index: 100000;
}

.notification-icon img{ 
    width: 2em;
    height: 2em;
    object-fit: contain;
    filter: brightness(.6);
    cursor: pointer;
}

.tervuren-notification-box{
    position: fixed;
    right: 0;
    top: 0;
    width: 20vw;
    height: 100vh;
    background-color: $white;
    z-index: 100000;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    .notification-header{
        width: 100%;
        height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $gray;
        h3{
            font-size: 1.5em;
            font-weight: 600;
        }
    }
    .notification-list{
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 1em;
        .notification-item{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1em;
            border-bottom: 1px solid $gray;
            .notification-item-text{
                width: 80%;
                display: flex;
                flex-flow: column nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                h4{
                    font-size: 1.2em;
                    font-weight: 600;
                }
                p{
                    font-size: 1em;
                    font-weight: 400;
                }
            }
            // .notification-item-icon{
            //     width: 20%;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     img{
            //         width: 2em;
            //         height: 2em;
            //         object-fit: contain;
            //     }
            // }
        }
    }
}