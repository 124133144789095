@import "../global-styles";

.split-card{
    box-sizing: border-box;
    display: flex; 
    flex-direction: column;
    width: 90%;  
    max-width: 1350px;  
    height: calc(80vh - $top_nav_height);
    background-color: $white;
    border: 1px solid $silver;
    border-radius: 5px;
    box-shadow: $box_shadow;
    color: $gray;
    overflow-y: auto;
    overflow-x: hidden;
    @include mq(1000px){
        flex-direction: row;
    }
    .split-card-left{
        border-right: 1px solid $silver;
        padding: 2.125em 2em;
        color: $gray;
        @include mq(1000px){
            overflow-y: auto;
            padding: 3.125em 2em;
            width: 18em;
        }
        img{
            width: 100%;
            height: 4em;
            object-fit: contain;
        }
        h3{
            color: $midnight;
            text-align: center;
            border-bottom: .1em solid $silver;
            margin-top: .5em;
            margin-bottom: .75em;
            padding-bottom: .5em;
        }
        span {
            font-size: 45px;
            line-height: 22px;
            left: -.4em !important;
            color: $indigo !important;
        }
        ul{
            list-style: none;
            li{
                padding-bottom: .5em;
                padding-top: 1em;
                cursor: pointer;
                &:hover{
                    color: $indigo;
                }
                &:active{
                    color: $indigo;
                }
            }
            ul{
                li{
                    margin-left: -1.5em;
                    cursor: pointer;
                &:hover{
                    color: $indigo;
                }
                &:active{
                    color: $indigo;
                }
                }
                
            }
        }
    }
    .split-card-right{
        flex: 0.8;  // Right side takes 75% of the width
        overflow-x: visible;
        padding: 2.125em 1em;
        position: relative;
        @include mq(650px){
            padding: 2.125em 2.5em;
        }
        @include mq(1000px){
			padding: 1rem;
            width: calc(100% - 18em);
        }
        button {
            margin-top: 2em;
            &.button-light {
                margin-top: 1.7em;
            }
        }
        .back-to-dash{
            position: absolute;
            top: -1.5em;
            left: 2em;
            text-decoration: none;
            color: $indigo;
            @include mq(1000px){
                left: unset;
                right: 2.5em;
                top: 1em;
            }
        }
    }
    .card-header{
        width: 100%;
        border-bottom: 2px solid $silver;
        margin-bottom: 1.5em;
        h2{
            color: #16202E;
        }
        p{
            padding: 1em 0;
        }

    }
}